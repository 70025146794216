/* CryptoTable.module.css */
.table {
  color: white	;
  background-color: #222;
  border-collapse: collapse;
  width: 100%;
  margin-top: 0px;
}

.searchInput {
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  
-webkit-appearance: none; 
-moz-appearance: none;
appearance: none;
  
-moz-border-radius: 3px;
-webkit-border-radius: 3px;
border-radius: 3px;
background: url(./pngegg.png) no-repeat;
background-position: right center;
background-color: white;
color: #000000;
border: 1px solid #000000;
background-size: 1em 1em;

  
} 

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #333;
}

tr:nth-child(even) {
  background-color: #2c2c2c;
}

/* Define a media query for narrow screens */
@media (max-width: 768px) {
  .table {
    max-width:100%;
    overflow:auto;
    color:white;
    display:block;
  }
  .crypto-table-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display:block;
    color:white;
  }

  .crypto-table-table {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display:block;
    max-width:50%;
    overflow:hidden;
    color:white;
  }
}
@media (max-width: 376px) {
  .table {
    max-width:100%;
    overflow:auto;
    color:white;
    display:block;
  }
  .crypto-table-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display:block;
    color:white;
  }
  .crypto-table-table {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display:block;
    max-width:50%;
    overflow:hidden;
    color:white;
  }
}

.logoImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover; /* This ensures that the image covers the full area without stretching */
}

.portfolioFormInputWrapper {
  webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: right center;
  background-color: white;
  color: #000000;
  border: 1px solid #ccc;
  padding:7px;
  margin:1px; 
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border:none; 
  padding:0px;
  margin: 10px;
  font-family: monospace;
}

.modalOpen {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modalClosed {
  display: none;
}

.modalContent {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
a:link { 
  color: white;
  text-decoration: none; 
} 
a:visited { 
  color: white;
  text-decoration: none; 
} 
a:hover { 
  color: white;
  text-decoration: underline; 
} 
a:active { 
  color: white;
  text-decoration: underline; 
}
